import React from "react";
import Layout from "components/layout";
import PrivacyPage from "./paths/privacy_page";
import FsAnalytics from "components/fs_analytics";

const Privacy = () => {
  return (
    <Layout>
      {/* Finstead Analytics */}
      <FsAnalytics />
      <PrivacyPage />
    </Layout>
  );
};
export default Privacy;
